import { mapGetters } from 'vuex';
import { DATE_FMT } from '@/utils/date_n_time';
import { AlertType, ResponseType } from '@/constants';
import { USER_REQUEST } from '@/store/actions/user';
import HasPlans from '@/modules/service-plan/mixins/HasPlans';
import { PLAN_TRANSITION_ACTION } from '@/modules/service-plan/tools/enums';
import { targetLevelCheckLoop } from '../tools/helpers';

const TimeoutingComponent = () => import('@/components/ui/TimeoutingComponent.vue');
const StripeForm = () => import('@/modules/stripe-elements/components/StripeForm.vue');

export default {
  name: 'ManageMembership',
  mixins: [HasPlans],
  components: {
    TimeoutingComponent,
    StripeForm,
  },
  data() {
    return {
      dateFormat: {},
      selectedPlan: null,
      changeLevelLoading: false,
      messageText: '',
      messageType: '',
      messageAction: null,
      messageActionText: '',

      stripeFormVisible: false,
      actionInProgress: false,
      timesServicePlansHasBeenUpdated: 0,
    };
  },

  computed: {
    ...mapGetters('userProfile', { isUserProfileDataReady: 'isDataReady' }),

    ...mapGetters('servicePlan', ['plansLoading']),

    loading() {
      return this.$store.state.userProfile.loading || this.changeLevelLoading;
    },

    noSelectedPlan() {
      return !this.selectedPlan?.action;
    },

    alertMessage() {
      switch(this.membershipStatus?.state) {
        case 'will-change': return 'Your subscription plan will change to {0} on the next renewal: ';
        case 'will-be-paid': return 'Next billing date on ';
        default: return '';
      }
    },
  },

  mounted() {
    // Request profile refresh
    this.$store.dispatch(USER_REQUEST);
    this.$store.dispatch('userProfile/getTransaction');
  },

  methods: {
    clearMessage() {
      this.messageText = '';
      this.messageType = '';
      this.messageAction = null;
      this.messageActionText = '';
    },

    async cancelMembership() {
      const cancelMembershipResult = await this.$store.dispatch('userProfile/cancelMembership');

      if (cancelMembershipResult.type === ResponseType.SUCCESS) {
        this.messageText = '$vuetify.userProfile.cancelSubscriptionSuccessMessage';
        this.messageType = AlertType.success;
      }
      if (cancelMembershipResult.type === ResponseType.ERROR) {
        this.messageText = '$vuetify.userProfile.cancelSubscriptionErrorMessage';
        this.messageType = AlertType.error;
      }
    },

    async upgradeMembership() {
      this.changeLevelLoading = true;

      this.clearMessage();
      const updateMembershipResult = await this.$store.dispatch('userProfile/updateMembership', this.targetLevel);

      this.decisionDialog = false;

      if (updateMembershipResult.type === ResponseType.SUCCESS) {
        await this.$store.dispatch(USER_REQUEST);
        await this.$store.dispatch('userProfile/getTransaction');
        const levelCheckLoopResult = await targetLevelCheckLoop(this.targetType);

        if (levelCheckLoopResult) {
          this.timesServicePlansHasBeenUpdated++;
          this.messageText = this.$vuetify.lang.t('$vuetify.userProfile.upgradeMembershipSuccessMessage');
          this.messageType = AlertType.success;
        }
      }
      if (updateMembershipResult.type === ResponseType.ERROR) {
        this.messageText = this.$vuetify.lang.t('$vuetify.userProfile.upgradeMembershipErrorMessage');
        this.messageType = AlertType.error;
      }

      this.changeLevelLoading = false;
    },

    async updateMembership(level, downgrade) {
      const updateMembershipResult = await this.$store.dispatch('userProfile/updateMembership', level.target);

      if (updateMembershipResult.type === ResponseType.SUCCESS) {
        if (downgrade) {
          this.messageText = '$vuetify.userProfile.downgradeMembershipSuccessMessage';
          this.messageType = AlertType.success;
        } else {
          await this.$store.dispatch(USER_REQUEST);
          await this.$store.dispatch('userProfile/getTransaction');
          const levelCheckLoopResult = await targetLevelCheckLoop(level.type);

          if (levelCheckLoopResult) {
            this.timesServicePlansHasBeenUpdated++;
            this.messageText = '$vuetify.userProfile.upgradeMembershipSuccessMessage';
            this.messageType = AlertType.success;
            this.messageAction = () => window.location.reload();
            this.messageActionText = 'Refresh page';
          }
        }
      }
      if (updateMembershipResult.type === ResponseType.ERROR) {
        if (downgrade) {
          this.messageText = '$vuetify.userProfile.downgradeMembershipErrorMessage';
        this.messageType = AlertType.error;
        } else {
          this.messageText = '$vuetify.userProfile.upgradeMembershipErrorMessage';
          this.messageType = AlertType.error;
        }
      }
    },

    onLoading($event) {
      this.actionInProgress = $event;
    },

    async confirmModifyMembership() {
      if (!this.selectedPlan?.action) {
        throw Error('No action selected.');
      }

      const { type, payload } = this.selectedPlan.action;

      this.changeLevelLoading = true;
      this.clearMessage();

      switch(type) {
        case PLAN_TRANSITION_ACTION.BUY:
          this.stripeFormVisible = true;
          break;
        case PLAN_TRANSITION_ACTION.CANCEL:
          this.actionInProgress = true;
          await this.cancelMembership(payload);
          this.selectedPlan = null;
          this.changeLevelLoading = false;
          this.actionInProgress = false;
          break;
        case PLAN_TRANSITION_ACTION.DOWNGRADE:
          this.actionInProgress = true;
          await this.updateMembership(payload, true);
          this.selectedPlan = null;
          this.changeLevelLoading = false;
          this.actionInProgress = false;
          break;
        case PLAN_TRANSITION_ACTION.UPDATE:
        case PLAN_TRANSITION_ACTION.UPGRADE:
          this.actionInProgress = true;
          await this.updateMembership(payload);
          this.selectedPlan = null;
          this.changeLevelLoading = false;
          this.actionInProgress = false;
          window.location.reload()
          break;
        default:
          // NOOP
      }
    },

    async changeLevelSubmit(result, level) {
      if (result) {
        this.stripeFormVisible = false;
        this.changeLevelLoading = true;
        this.clearMessage();

        await this.$store.dispatch('userProfile/getTransaction');
        const levelCheckLoopResult = await targetLevelCheckLoop(level);

        if (levelCheckLoopResult) {
          this.timesServicePlansHasBeenUpdated++;
          // TODO: Consider better way to display message
          this.messageText = '$vuetify.userProfile.newMembershipSuccessMessage';
          this.messageType = AlertType.success;
        }

        this.changeLevelLoading = false;
        this.selectedPlan = null;
        this.stripeFormVisible = false;
        this.actionInProgress = false;
      }
    },

    allowSelectPlan(plan) {
        if (!this.actionInProgress && plan.action && this.membershipStatus && !(this.membershipStatus.state === 'will-change'
          && this.membershipStatus.nextPlan === 'LEVEL-0')) {
            return true;
        }

        return false;
    },

    selectPlan(plan) {
      if (this.allowSelectPlan(plan)) {
        this.selectedPlan = plan;
      }
    }
  },
};
