import isEmpty from 'lodash/isEmpty';
import { sleep } from '@/utils/helpers';
import Store from '../../../store';
import {
  submodules as servicePlanSubmodules,
  submoduleActions as servicePlanSubmoduleActions,
} from '../../service-plan/tools/enums';

/**
 * Looks for matching with level plan, where owned is positive
 *
 * @param {String} level Plan level, ex. LEVEL-1
 */
const targetLevelCheckLoop = async (level) => {
  const maxAttemtps = 10;
  const timeout = 1000 * 5;
  let attempt = 0;
  let plans = {};

  const shouldRepeat = () => isEmpty(plans) || !Object.values(plans).some((plan) => plan.type === level && plan.owned);

  while (++attempt < maxAttemtps && shouldRepeat()) {
    // eslint-disable-next-line no-await-in-loop
    plans = await Store.dispatch(`${servicePlanSubmodules.servicePlan}/${servicePlanSubmoduleActions.plansRequest}`);

    if (shouldRepeat()) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(timeout);
    } else {
      break;
    }
  }

  if (shouldRepeat() && attempt === maxAttemtps) {
    return false;
  }
  return true;
};

export { targetLevelCheckLoop };
